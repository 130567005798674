main-green = #A4BD73
fg-green = #4D6B12
main-brown = #92846F

body
  color: #7E7E7E

#selling-is-tough
  background: main-green
  color: white

  article
    h1
      color: fg-green

    a
      color: white

#prepaid-remedy
  > h1
    color: main-green

#growing-market
  background: main-brown
  color: white

.pricing
  p.price
    color: black
