body
  font: 16px "Oswald"
  font-weight: 200
  padding: 0

h1, h2, h3
  font-weight: 200

.jumbotron article h1
  font-size: 22px

#prepaid-remedy
  article
    h1
      font-weight: 400

    h1, p
      font-size: 22px

#engage-plug
  font-size: 22px

  .pricing
    font-size: 27px

    .price
      font-size: 79px
