site-width = 900px

header[role="main"]
  max-width: site-width
  margin: 0 auto

  img
    display: block

.jumbotron
  max-width: site-width
  margin: 0 auto
  text-align: center

section > h1, .jumbotron > h1
  text-align: center
  margin: 20px 0 80px 0

#selling-is-tough, #shortcomings, #prepaid-remedy, #growing-market, #engage-plug
  padding: 40px 0

  h1 + h2
    margin-top: -70px

#shortcomings
  display: flex
  justify-content: space-between

  > article
    flex: 1
    margin: 0px 20px

    &:first-child, &:last-child
      margin: 0

    p
      text-align: left

#prepaid-remedy
  article
    display: flex
    margin: 0 auto
    margin-bottom: 40px
    max-width: 500px

    h1, p
      margin: 0

    div.image-wrapper
      width: 120px
      flex-shrink: 0
      text-align: center

#growing-market
  h2
    text-align: center

  #charts
    align-items: center
    width: 1000px
    margin: 50px auto
    position: relative

    h1
      text-align: center

  #growth-chart
    display: block
    margin: 0 auto
    margin-bottom: 50px
    height: 400px

  #demographic-chart, #use-chart
    height: 200px

  #correlation
    position: absolute
    top: 655px
    left: 140px

  #take-advantage
    width: site-width - 200px
    margin: 40px auto 0 auto
    font-size: 24px
    text-align: center

    a
      color: white

  .demographic-comparison
    display: flex
    justify-content: space-around

  .legend
    list-style: none
    width: 140px
    margin: 20px auto

    li
      span
        width: 24px
        margin-right: 5px
        display: inline-block

      &:first-child span
        border-radius: 3px 3px 0 0

      &:last-child span
        border-radius: 0 0 3px 3px

#engage-plug
  max-width: site-width - 250px
  margin: 0 auto

  > img
    display: block
    margin: 0 auto
    margin-bottom: 30px

  .pricing
    text-align: center
    margin: 40px 0

    p
      margin: 0

  .links
    display: flex
    justify-content: center

    a
      display: block
      width: 250px
      padding: 10px 0
      text-align: center
      background: grey
      color: white
      border-radius: 5px
      text-decoration: none

      &:first-child
        margin-right: 20px

      background: main-brown

      &.button-primary
        background: main-green
